<template>
  <v-menu
    :close-on-content-click="false"
    :activator="activatorNode"
    z-index="50"
    offset-y
    left
    @click.native.stop
  >
    <v-list>
      <template v-for="sprintStatus in statuses">
        <v-list-item
          v-if="
            currentUserPermissionInSelectedProject !== 'client' || !sprintStatus.hiddenForClients
          "
          :key="sprintStatus.value"
          :disabled="loadingSprintStatuses[sprintStatus.value] || sprintStatus.disabled"
          @click.stop="toggleSprintByStatus(sprintStatus.value)"
        >
          <v-list-item-action>
            <v-progress-circular
              v-if="loadingSprintStatuses[sprintStatus.value]"
              width="3"
              size="24"
              indeterminate
            />
            <v-icon v-else-if="displayedSprintStatuses[sprintStatus.value]" color="primary"
              >check_box</v-icon
            >
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ sprintStatus.text }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProjectSprintDisplayedStatusesMenu',

  data() {
    return {
      activatorNode: null,
      displayedSprintStatuses: {
        planned: true,
        started: true,
        postponed: true,
      },
      loadingSprintStatuses: {},
    };
  },

  computed: {
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    statuses() {
      return [
        {
          value: 'planned',
          text: this.$t('projects.controls.show_planned_sprints'),
          disabled: true,
        },
        {
          value: 'started',
          text: this.$t('projects.controls.show_started_sprints'),
          disabled: true,
        },
        {
          value: 'postponed',
          text: this.$t('projects.controls.show_postponed_sprints'),
          disabled: true,
        },
        { value: 'closed', text: this.$t('projects.controls.show_closed_sprints') },
        {
          value: 'supported',
          text: this.$t('projects.controls.show_supported_sprints'),
          hiddenForClients: true,
        },
      ];
    },
  },

  mounted() {
    this.activatorNode = document.querySelector('.js-toggle-sprint-statuses-menu');
  },

  methods: {
    ...mapMutations('projectSprints', ['DELETE_PROJECT_SPRINTS_BY_STATUS']),
    ...mapActions('projectSprints', ['fetchProjectSprints']),

    async toggleSprintByStatus(status) {
      if (this.displayedSprintStatuses[status]) {
        this.displayedSprintStatuses[status] = false;
        this.DELETE_PROJECT_SPRINTS_BY_STATUS(status);
        return;
      }

      this.$set(this.displayedSprintStatuses, status, true);
      this.$set(this.loadingSprintStatuses, status, true);
      await this.fetchProjectSprints({ status, project_id: this.$route.params.projectId });
      this.$delete(this.loadingSprintStatuses, status);
    },
  },
};
</script>
