var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-body-2",class:{ 'loading-overlay': _vm.loading },on:{"mouseover":function($event){return _vm.onCellHover('', null)}}},[_c('table',{staticClass:"time-tracking-table full-width"},[(!_vm.hideHeaders)?_c('thead',[(_vm.userFullName)?_c('th'):_vm._e(),_c('th',{staticClass:"text-left px-2 pb-1",staticStyle:{"vertical-align":"bottom"}},[_vm._v(" "+_vm._s(_vm.$t('general.sprint'))+" ")]),_vm._l((_vm.displayedDates),function(d,index){return _c('th',{key:d.date,staticClass:"text-center",class:[
          {
            'hovered-time-tracking-cell': _vm.hoveredCellDate === d.date,
          },
          _vm.getBackgroundColor(d) ]},[[_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(d.date, 'EEEEEE'))+" ")]),_c('div',{staticClass:"d-flex justify-center mb-1"},[(index === 0 || _vm.isFirstDayOfMonth(new Date(d.date)))?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.formatDate(d.date, 'LLL'))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-center",class:{ 'header-today today': _vm.today === d.date }},[_vm._v(" "+_vm._s(_vm.formatDate(d.date, 'dd'))+" ")])])]],2)})],2):_vm._e(),_c('tbody',[_vm._l((_vm.sprints),function(sprint){return _c('tr',{key:sprint.id,staticClass:"time-tracking-row",class:{
          'hovered-time-tracking-row': sprint.sprint_id === _vm.hoveredSprintId,
        }},[(_vm.userFullName)?_c('td',{staticClass:"py-1 pl-2",staticStyle:{"width":"36px"}},[_c('UserAvatar',{attrs:{"full-name":_vm.userFullName,"z-index":10}})],1):_vm._e(),_c('td',{staticClass:"px-2",class:[{ 'grey--text': _vm.opaque }, ("py-" + (_vm.showProjectTitles ? 1 : 2))]},[(_vm.showProjectTitles)?_c('div',[_vm._v(_vm._s(sprint.project_title))]):_vm._e(),_vm._v(" "+_vm._s(sprint.sprint_name)+" ")]),_vm._l((_vm.displayedDates),function(d){return _c('td',{key:((d.date) + "-" + (sprint.id) + "-" + _vm.search),staticClass:"day-cell text-center px-2",class:[
            { 'hovered-time-tracking-cell': _vm.hoveredCellDate === d.date },
            { clickable: _vm.canChange },
            { 'grey--text': _vm.opaque },
            ("py-" + (_vm.showProjectTitles ? 1 : 2)),
            _vm.getBackgroundColor(d) ],style:({ width: _vm.dayCellWidth }),attrs:{"contenteditable":_vm.canChange},on:{"mouseenter":function($event){return _vm.onCellHover(d.date, sprint)},"mouseover":function($event){$event.stopPropagation();},"blur":function($event){return _vm.save($event, d.date, sprint)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}}},[_vm._v(" "+_vm._s(+sprint.time_spent[d.date] || '')+" ")])})],2)}),(_vm.showSummaryRow)?_c('tr',{style:({ background: _vm.$vuetify.theme.currentTheme.weekend })},[_c('td',{staticClass:"text-no-wrap pa-2"},[_vm._v(" "+_vm._s(_vm.$t('general.total'))+" ")]),_vm._l((_vm.displayedDates),function(d){return _c('td',{key:d.date,staticClass:"text-center py-1 px-2",class:[
            {
              'hovered-time-tracking-cell': _vm.hoveredCellDate === d.date,
            },
            _vm.getBackgroundColor(d) ],style:({ width: _vm.dayCellWidth })},[_vm._v(" "+_vm._s(_vm.aggregateRow[d.date])+" ")])})],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }