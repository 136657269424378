<template>
  <v-scale-transition origin="center">
    <v-speed-dial
      v-if="expandableActions.length && showMainButton"
      v-model="isSpeedDialExpanded"
      :open-on-hover="!$store.state.settings.isTouchDevice"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-tooltip :disabled="!actions[0].tooltip" left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" dark fab @click="actions[0].callback">
              <v-icon v-if="isSpeedDialExpanded" color="secondary">{{ actions[0].icon }}</v-icon>
              <v-icon v-else color="secondary">{{ icon }}</v-icon>
            </v-btn>
          </template>
          <div>{{ actions[0].tooltip }}</div>
        </v-tooltip>
      </template>

      <v-tooltip
        v-for="action in expandableActions"
        :key="action.icon"
        :disabled="!action.tooltip"
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab small @click="action.callback">
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </template>
        <div>{{ action.tooltip }}</div>
      </v-tooltip>
    </v-speed-dial>

    <v-btn
      v-else-if="showMainButton && actions.length"
      color="primary"
      bottom
      dark
      fab
      fixed
      right
      @click.stop="actions[0].callback"
    >
      <v-icon color="secondary">
        {{ actions[0].icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'BaseActionsSpeedDial',

  props: {
    icon: {
      type: String,
      default: 'add',
    },
    actions: {
      type: Array,
      default: () => [
        // { icon: '', tooltip: '', callback: () => { ... } },
      ],
      required: true,
    },
    displayDelay: {
      type: Number,
      default: 1000,
    },
  },

  data() {
    return {
      showMainButton: false,
      isSpeedDialExpanded: false,
    };
  },

  computed: {
    expandableActions() {
      return this.actions.slice(1);
    },
  },

  mounted() {
    setTimeout(() => {
      this.showMainButton = true;
    }, this.displayDelay);
  },
};
</script>

<style scoped></style>
