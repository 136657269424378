<template>
  <BaseActionMenu :actions="getUnassignedUserStoryActions()" always-visible />
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getDefaultProjectUserStoryFormItem } from '@/store/modules/project-user-stories-module';

export default {
  name: 'ProjectPageUnassignedUserStoryActions',

  components: { BaseActionMenu },

  computed: {
    ...mapState('projectUserStories', ['newProjectUserStory']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_NEW_PROJECT_USER_STORY']),

    createUserStory() {
      if (this.sprint_id) {
        this.SET_NEW_PROJECT_USER_STORY({
          ...getDefaultProjectUserStoryFormItem(),
          project_id: this.selectedProject.id || null,
        });
      }

      this.$router.push({ name: 'createProjectUserStory' });
    },

    getUnassignedUserStoryActions() {
      const actions = [];

      if (this.currentUserPermissionInSelectedProject !== 'supplier') {
        actions.unshift({
          icon: 'add',
          label: this.$t('projects.general.add_user_story'),
          callback: this.createUserStory,
        });
      }

      if (this.currentUserPermissionInSelectedProject === 'admin') {
        actions.push({
          icon: 'open_in_new',
          label: this.$t('projects.general.stories_copying'),
          callback: () => this.$router.push({ name: 'unassignedUserStoriesSummary' }),
        });
      }

      return actions;
    },
  },
};
</script>

<style scoped></style>
