<template>
  <v-scale-transition v-if="showUserStoryMoveButton" origin="center">
    <v-btn
      color="primary"
      fab
      fixed
      bottom
      right
      @click.stop="$router.push({ name: 'moveSelectedUserStories' })"
    >
      <v-badge :content="selectedUserStoriesCount" :value="selectedUserStoriesCount" color="error">
        <v-icon color="secondary">forward</v-icon>
      </v-badge>
    </v-btn>
  </v-scale-transition>
  <BaseActionsSpeedDial v-else :actions="getSpeedDialActions()" :display-delay="0" />
</template>

<script>
import BaseActionsSpeedDial from '@/components/base/BaseActionsSpeedDial';
import { getDefaultProjectUserStoryFormItem } from '@/store/modules/project-user-stories-module';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProjectPageSpeedDial',

  components: { BaseActionsSpeedDial },

  computed: {
    ...mapState('projectUserStories', [
      'newProjectUserStory',
      'enableProjectUserStoryCheckboxes',
      'selectedProjectUserStoriesMap',
    ]),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    selectedUserStoriesCount() {
      return Object.values(this.selectedProjectUserStoriesMap).length;
    },

    showUserStoryMoveButton() {
      return (
        this.selectedUserStoriesCount > 0 &&
        this.enableProjectUserStoryCheckboxes &&
        this.currentUserPermissionInSelectedProject === 'admin'
      );
    },
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_NEW_PROJECT_USER_STORY']),

    createUserStory() {
      if (this.newProjectUserStory.sprint_id) {
        this.SET_NEW_PROJECT_USER_STORY({
          ...getDefaultProjectUserStoryFormItem(),
          project_id: this.selectedProject.id || null,
        });
      }

      this.$router.push({ name: 'createProjectUserStory' });
    },

    getSpeedDialActions() {
      const actions = [
        {
          icon: 'comment',
          tooltip: this.$t('projects.modal_titles.new_story'),
          callback: this.createUserStory,
        },
        {
          icon: 'shopping_cart',
          tooltip: this.$t('projects.controls.mass_insertion'),
          callback: () => this.$router.push({ name: 'createProjectUserStoryBulk' }),
        },
      ];

      if (
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee() && this.$can(['projects.*']))
      ) {
        actions.push({
          icon: 'directions_run',
          tooltip: this.$t('projects.modal_titles.new_sprint'),
          callback: () => this.$router.push({ name: 'createProjectSprint' }),
        });
      }

      if (this.currentUserPermissionInSelectedProject !== 'supplier') {
        actions.push({
          icon: 'bug_report',
          tooltip: this.$t('projects.modal_titles.new_issue'),
          callback: () => this.$router.push({ name: 'project_createProjectIssue' }),
        });
      }

      return actions;
    },
  },
};
</script>

<style scoped></style>
