<template>
  <v-expansion-panel class="mb-3 mt-0">
    <ProjectSprintProgressBar
      :sprint="sprint"
      :user-stories="projectUserStoriesBySprint[sprint.id]"
      :loading="loading[`get:api/project-sprints/${sprint.id}`]"
    />

    <v-expansion-panel-header class="project-page-panel-header" expand-icon="">
      <div class="text-subtitle-2">
        <div>
          {{ sprint.sprint_name.toUpperCase() }}
          {{ sprint.starts_at }} - {{ sprint.deadline }}
          <span :class="sprint.status === 'started' ? 'text-success' : 'grey--text'"
            >({{ $t(`projects.labels.${sprint.status}`).toLowerCase() }})</span
          >
        </div>
        <div v-if="userStories.length > 0">
          {{ $t('projects.general.completed_stories') }}: {{ completedUserStoryCount }}/{{
            sprint.user_stories.length
          }}
        </div>
        <div v-if="totalSprintPoints > 0">
          {{ $t('projects.general.collected_points') }}: {{ completedSprintPoints }}/{{
            totalSprintPoints
          }}
        </div>
      </div>

      <BaseActionMenu :actions="getSprintActions()" always-visible />
    </v-expansion-panel-header>

    <v-expansion-panel-content class="sprint-panel">
      <ProjectUserStoriesList
        :user-stories="userStories"
        :sprint-id="sprint.id"
        :can-reorder="
          currentUserPermissionInSelectedProject === 'admin' ||
          ($isEmployee() && $can(['projects.*']))
        "
        can-change-point-statuses
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProjectUserStoriesList from '@/components/ProjectUserStoriesList';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import ProjectSprintProgressBar from '@/components/ProjectSprintProgressBar';
import { getDefaultProjectUserStoryFormItem } from '@/store/modules/project-user-stories-module';

export default {
  name: 'ProjectSprintPanel',

  components: { ProjectSprintProgressBar, BaseActionMenu, ProjectUserStoriesList },

  props: {
    sprint: {
      type: Object,
      required: true,
    },
    userStories: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(['loading']),
    ...mapState('projectUserStories', ['projectUserStoriesBySprint', 'newProjectUserStory']),
    ...mapState('projects', ['selectedProject']),
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    completedSprintPoints() {
      let points = 0;

      this.userStories.forEach((userStory) => {
        this.projectUserStoryPointTypes.forEach(({ statusFieldKey, estimatedPointsFieldKey }) => {
          if (userStory[statusFieldKey] === 'closed') {
            points += +userStory[estimatedPointsFieldKey];
          }
        });
      });

      return points;
    },

    totalSprintPoints() {
      let points = 0;

      this.userStories.forEach((userStory) => {
        this.projectUserStoryPointTypes.forEach(({ estimatedPointsFieldKey }) => {
          points += +userStory[estimatedPointsFieldKey];
        });
      });

      return points;
    },

    completedUserStoryCount() {
      let count = 0;

      this.userStories.forEach((userStory) => {
        if (userStory.status === 'done') {
          count += 1;
        }
      });

      return count;
    },
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_NEW_PROJECT_USER_STORY']),
    ...mapActions('projectSprints', ['deleteProjectSprint']),

    createUserStory() {
      if (this.newProjectUserStory.sprint_id !== this.sprint.id) {
        this.SET_NEW_PROJECT_USER_STORY({
          ...getDefaultProjectUserStoryFormItem(),
          sprint_id: this.sprint.id,
          project_id: this.selectedProject.id || null,
        });
      }

      this.$router.push({ name: 'createProjectUserStory' });
    },

    getSprintActions() {
      if (
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee() && this.$can(['projects.*']))
      ) {
        return [
          {
            icon: 'add',
            label: this.$t('projects.general.add_user_story'),
            callback: this.createUserStory,
          },
          {
            icon: 'open_in_new',
            label: this.$t('projects.general.stories_copying'),
            callback: () =>
              this.$router.push({
                name: 'projectSprintUserStoriesSummary',
                params: { sprintId: this.sprint.id },
              }),
          },
          {
            icon: 'edit',
            label: this.$t('general.controls.edit'),
            callback: () =>
              this.$router.push({
                name: 'editProjectSprint',
                params: { projectSprintId: this.sprint.id },
              }),
          },
          {
            icon: 'delete',
            label: this.$t('general.controls.delete'),
            callback: () => this.deleteProjectSprint(this.sprint),
          },
        ];
      }
      return [];
    },
  },
};
</script>

<style scoped></style>
