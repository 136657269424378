<template>
  <v-expansion-panel class="mb-3">
    <v-expansion-panel-header expand-icon="" class="project-page-panel-header py-2">
      <div class="text-subtitle-2">
        {{ $t('general.issues').toUpperCase() }}
      </div>
      <div class="d-flex align-center justify-end">
        <ProjectIssueFilter
          v-if="!$isClient()"
          :filter-params="projectIssueFilterParams"
          :employees="selectedProject.assignees"
          hide-project-filter
          hide-chips
          @change="onFilterChange"
          @reset="onFilterReset"
        />
        <v-btn
          v-if="currentUserPermissionInSelectedProject !== 'supplier'"
          class="mr-1"
          icon
          @click.stop="$router.push({ name: 'project_createProjectIssue' })"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="sprint-panel mt-0">
      <ProjectIssueList
        :items="projectIssues"
        :pagination="projectIssuePagination"
        :loading="
          loading['get:api/project-issues'] && !loading[`get:api/projects/${nextProjectId}`]
        "
        can-change-status
        @click:row="
          $router.push({
            name: 'project_editProjectIssue',
            params: { projectIssueId: $event.id },
          })
        "
        @update:status="onProjectIssueStatusChange"
        @update:page="fetchProjectIssues({ ...projectIssueFilterParams, page: $event })"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProjectIssueList from '@/components/ProjectIssueList';
import ProjectIssueFilter from '@/components/filters/ProjectIssueFilter';
import { getDefaultProjectIssueFilterParams } from '@/store/modules/project-issues-module';
import clone from 'just-clone';

export default {
  name: 'ProjectPageIssues',

  components: { ProjectIssueFilter, ProjectIssueList },

  props: {
    // to avoid double opacity decrease due to multiple .content-loading layers
    nextProjectId: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectIssues', [
      'projectIssues',
      'projectIssuePagination',
      'projectIssueFilterParams',
    ]),
    ...mapGetters('projectIssues', ['projectIssuePriorities', 'projectIssueStatuses']),
    ...mapGetters(['loading']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
  },

  beforeDestroy() {
    this.SET_PROJECT_ISSUE_FILTER_PARAMS(getDefaultProjectIssueFilterParams());
  },

  methods: {
    ...mapMutations('projectIssues', ['SET_PROJECT_ISSUE_FILTER_PARAMS']),
    ...mapActions('projectIssues', ['updateProjectIssueStatus', 'fetchProjectIssues']),

    onProjectIssueStatusChange(newStatus, issue) {
      if (newStatus === issue.status) {
        return;
      }

      if (newStatus === 'closed') {
        this.$router.push({
          name: 'project_concludeProjectIssue',
          params: { projectIssueId: issue.id },
        });
      } else {
        this.updateProjectIssueStatus({
          ...issue,
          status: newStatus,
        });
      }
    },

    onFilterChange(params) {
      this.fetchProjectIssues({
        ...params,
        project_id: +this.$route.params.projectId,
      });

      if (!this.$route.query['is-filter-open']) {
        return;
      }

      const query = clone(this.$route.query);
      delete query['is-filter-open'];
      this.$router.push({
        ...this.$route,
        query,
      });
    },

    onFilterReset() {
      this.fetchProjectIssues({
        ...getDefaultProjectIssueFilterParams(),
        project_id: +this.$route.params.projectId,
      });
    },
  },
};
</script>

<style scoped></style>
