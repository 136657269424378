<template>
  <v-container
    :class="{ 'content-loading': loading[`get:api/projects/${nextProjectId}`] }"
    class="mb-16"
    fluid
  >
    <div class="d-flex align-center justify-space-between">
      <h1 class="text-h6 px-2">
        {{ selectedProject.title }}
      </h1>

      <ProjectPageActions v-if="currentUserPermissionInSelectedProject === 'admin'" />
    </div>

    <v-row class="pt-2" dense>
      <v-col md="6" cols="12">
        <v-expansion-panels v-model="expandedOtherPanelIndexes" popout multiple>
          <ProjectPageIssues :next-project-id="nextProjectId" />

          <ProjectPageTimeTracking
            v-if="currentUserPermissionInSelectedProject !== 'client' && !$isClient()"
          />

          <v-expansion-panel class="mt-0">
            <v-expansion-panel-header class="project-page-panel-header" expand-icon="">
              <div class="text-subtitle-2">
                {{ $t('projects.general.unassigned_requirements').toUpperCase() }}
              </div>

              <ProjectPageUnassignedUserStoryActions />
            </v-expansion-panel-header>

            <v-expansion-panel-content class="sprint-panel mt-0">
              <ProjectUserStoriesList
                :user-stories="projectUserStoriesBySprint[null]"
                :can-reorder="
                  currentUserPermissionInSelectedProject === 'admin' ||
                  ($isEmployee() && $can(['projects.*']))
                "
                can-change-point-statuses
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col md="6" cols="12" order-md="2">
        <v-expansion-panels
          :value="expandedSprintPanelIndexes"
          :key="expansionPanelResetCounter"
          popout
          multiple
          @change="SET_EXPANDED_SPRINT_PANEL_INDEXES"
        >
          <ProjectSprintPanel
            v-for="sprint in projectSprints"
            :key="sprint.id"
            :sprint="sprint"
            :user-stories="projectUserStoriesBySprint[sprint.id]"
          />
        </v-expansion-panels>
      </v-col>
    </v-row>

    <ProjectPageSpeedDial
      v-if="
        currentUserPermissionInSelectedProject === 'admin' ||
        ($isEmployee() && $can(['projects.*']))
      "
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ProjectSprintPanel from '@/components/ProjectSprintPanel';
import ProjectUserStoriesList from '@/components/ProjectUserStoriesList';
import ProjectPageActions from '@/components/ProjectPageActions';
import ProjectPageSpeedDial from '@/components/ProjectPageSpeedDial';
import ProjectPageUnassignedUserStoryActions from '@/components/ProjectPageUnassignedUserStoryActions';
import ProjectPageIssues from '@/views/project-issues/ProjectPageIssues';
import ProjectPageTimeTracking from '@/views/time-tracking/ProjectPageTimeTracking';
import { hideTopProgressBar, showTopProgressBar } from '@/util/event-bus';
import { getDefaultProjectUserStoryFormItem } from '@/store/modules/project-user-stories-module';
import { getDefaultProjectSprintFormItem } from '@/store/modules/project-sprints-module';

export default {
  name: 'Project',

  components: {
    ProjectPageTimeTracking,
    ProjectPageIssues,
    ProjectPageUnassignedUserStoryActions,
    ProjectPageSpeedDial,
    ProjectPageActions,
    ProjectUserStoriesList,
    ProjectSprintPanel,
  },

  data() {
    return {
      nextProjectId: null,
      expandedOtherPanelIndexes: [0, 1, 2], // 0 - errors, 1 - time tracking, 2 - unassigned user stories
      expansionPanelResetCounter: 0, // fixes 1st sprint panel collapsing when navigating from one project to another
    };
  },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectSprints', ['projectSprints']),
    ...mapState('projectUserStories', ['projectUserStoriesBySprint']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters(['loading']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters('projectSprints', ['expandedSprintPanelIndexes']),
  },

  beforeRouteEnter(to, from, next) {
    const projectId = +to.params.projectId;
    showTopProgressBar();

    store
      .dispatch('projects/selectProject', projectId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute());
      });
  },

  created() {
    this.setupProjectView();
  },

  async beforeRouteUpdate(to, from, next) {
    if (to.params.projectId && +to.params.projectId !== +from.params.projectId) {
      this.nextProjectId = to.params.projectId;
      await this.selectProject(+to.params.projectId);
      this.setupProjectView();
      this.expansionPanelResetCounter += 1;
    }
    next();
  },

  destroyed() {
    this.SET_SELECTED_PROJECT({});
  },

  methods: {
    ...mapActions('projects', ['selectProject']),
    ...mapMutations('projects', ['SET_SELECTED_PROJECT']),
    ...mapMutations('projectSprints', [
      'SET_PROJECT_SPRINTS',
      'SET_EXPANDED_SPRINT_PANEL_INDEXES',
      'SET_NEW_PROJECT_SPRINT',
    ]),
    ...mapMutations('projectUserStories', [
      'SET_PROJECT_SPRINT_USER_STORIES',
      'SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES',
      'SET_NEW_PROJECT_USER_STORY',
    ]),

    async setupProjectView() {
      this.SET_PROJECT_SPRINTS(this.selectedProject.sprints);
      for (let i = 0; i < this.selectedProject.sprints.length; i++) {
        const { id, user_stories } = this.selectedProject.sprints[i];
        this.SET_PROJECT_SPRINT_USER_STORIES({ sprintId: id, userStories: user_stories });
      }
      this.SET_PROJECT_SPRINT_USER_STORIES({
        sprintId: null,
        userStories: this.selectedProject.user_stories_not_assigned,
      });
      this.SET_NEW_PROJECT_USER_STORY(getDefaultProjectUserStoryFormItem());
      this.SET_NEW_PROJECT_SPRINT(getDefaultProjectSprintFormItem());
      this.SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES(false);
      hideTopProgressBar();
    },
  },
};
</script>
