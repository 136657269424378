<template>
  <div
    :class="{ 'loading-overlay': loading }"
    class="text-body-2"
    @mouseover="onCellHover('', null)"
  >
    <table class="time-tracking-table full-width">
      <thead v-if="!hideHeaders">
        <th v-if="userFullName"></th>

        <th style="vertical-align: bottom" class="text-left px-2 pb-1">
          {{ $t('general.sprint') }}
        </th>
        <th
          v-for="(d, index) in displayedDates"
          :key="d.date"
          :class="[
            {
              'hovered-time-tracking-cell': hoveredCellDate === d.date,
            },
            getBackgroundColor(d),
          ]"
          class="text-center"
        >
          <template>
            <div>
              {{ formatDate(d.date, 'EEEEEE') }}
            </div>
            <div class="d-flex justify-center mb-1">
              <div v-if="index === 0 || isFirstDayOfMonth(new Date(d.date))" class="mr-1">
                {{ formatDate(d.date, 'LLL') }}
              </div>
              <div
                :class="{ 'header-today today': today === d.date }"
                class="d-flex align-center justify-center"
              >
                {{ formatDate(d.date, 'dd') }}
              </div>
            </div>
          </template>
        </th>
      </thead>

      <tbody>
        <tr
          v-for="sprint in sprints"
          :key="sprint.id"
          :class="{
            'hovered-time-tracking-row': sprint.sprint_id === hoveredSprintId,
          }"
          class="time-tracking-row"
        >
          <td v-if="userFullName" class="py-1 pl-2" style="width: 36px">
            <UserAvatar :full-name="userFullName" :z-index="10" />
          </td>
          <td :class="[{ 'grey--text': opaque }, `py-${showProjectTitles ? 1 : 2}`]" class="px-2">
            <div v-if="showProjectTitles">{{ sprint.project_title }}</div>
            {{ sprint.sprint_name }}
          </td>
          <td
            v-for="d in displayedDates"
            :key="`${d.date}-${sprint.id}-${search}`"
            :class="[
              { 'hovered-time-tracking-cell': hoveredCellDate === d.date },
              { clickable: canChange },
              { 'grey--text': opaque },
              `py-${showProjectTitles ? 1 : 2}`,
              getBackgroundColor(d),
            ]"
            :contenteditable="canChange"
            :style="{ width: dayCellWidth }"
            class="day-cell text-center px-2"
            @mouseenter="onCellHover(d.date, sprint)"
            @mouseover.stop
            @blur="save($event, d.date, sprint)"
            @keydown.enter="$event.target.blur()"
          >
            {{ +sprint.time_spent[d.date] || '' }}
          </td>
        </tr>

        <tr v-if="showSummaryRow" :style="{ background: $vuetify.theme.currentTheme.weekend }">
          <td class="text-no-wrap pa-2">
            {{ $t('general.total') }}
          </td>
          <td
            v-for="d in displayedDates"
            :key="d.date"
            :class="[
              {
                'hovered-time-tracking-cell': hoveredCellDate === d.date,
              },
              getBackgroundColor(d),
            ]"
            :style="{ width: dayCellWidth }"
            class="text-center py-1 px-2"
          >
            {{ aggregateRow[d.date] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import UserAvatar from '@/components/UserAvatar';
import { eachDayOfInterval, format, isFirstDayOfMonth } from 'date-fns';
import round from '@/util/numbers';
import { mapGetters } from 'vuex';

export default {
  name: 'TimeTrackingTable',

  components: { UserAvatar },

  props: {
    projects: {
      type: Array,
      required: true,
    },
    filterParams: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    canChange: {
      type: Boolean,
      default: false,
    },
    hideHeaders: {
      type: Boolean,
      default: false,
    },
    userFullName: {
      type: String,
      default: '',
    },
    opaque: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    projectId: {
      type: Number,
      default: null,
    },
    userId: {
      type: Number,
      required: true,
    },
    showSummaryRow: {
      type: Boolean,
      default: false,
    },
    highlightWeekends: {
      type: Boolean,
      default: false,
    },
    showProjectTitles: {
      type: Boolean,
      default: false,
    },
    highlightHoveredRowAndColumn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hoveredCellDate: '',
      hoveredSprintId: '',
      today: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  computed: {
    ...mapGetters('settings', ['defaultDateFnsConfig']),

    displayedDates() {
      const { date_from, date_to } = this.filterParams;
      const dates = eachDayOfInterval({ start: new Date(date_from), end: new Date(date_to) });
      return dates.map((d) => ({
        weekday: format(d, 'i'),
        date: format(d, 'yyyy-MM-dd'),
      }));
    },

    sprints() {
      const sprints = [];
      const search = this.search.trim().toLowerCase();
      for (let i = 0; i < this.projects.length; i++) {
        const project = this.projects[i];
        for (let j = 0; j < project.sprints.length; j++) {
          if (
            project.project_title.toLowerCase().includes(search) ||
            project.sprints[j].sprint_name.toLowerCase().includes(search)
          ) {
            sprints.push({
              ...project.sprints[j],
              project_title: project.project_title,
              project_id: project.project_id,
            });
          }
        }
      }
      return sprints;
    },

    dayCellWidth() {
      return `${round(75 / this.displayedDates.length)}%`;
    },

    aggregateRow() {
      const aggregateRow = {};

      for (let i = 0; i < this.displayedDates.length; i++) {
        const { date } = this.displayedDates[i];
        aggregateRow[date] = null;
        for (let j = 0; j < this.sprints.length; j++) {
          if (this.sprints[j].time_spent[date]) {
            aggregateRow[date] += +this.sprints[j].time_spent[date];
          }
        }
      }

      return aggregateRow;
    },
  },

  methods: {
    formatDate(date, formatString) {
      return format(new Date(date), formatString, this.defaultDateFnsConfig);
    },

    isFirstDayOfMonth,

    getBackgroundColor(date) {
      if (this.highlightWeekends && ['6', '7'].includes(date.weekday)) {
        return 'weekend';
      }
      return '';
    },

    save(event, date, sprint) {
      if (!this.canChange) {
        return;
      }
      const previousValue = +sprint.time_spent[date] || 0;
      const newValue = +event.target.innerText.replace(',', '.').trim();

      if (!newValue && newValue !== 0) {
        event.target.innerText = sprint.time_spent[date] || '';
        return;
      }
      if (newValue === previousValue) {
        return;
      }
      const payload = {
        date,
        user_id: this.userId,
        sprint_id: sprint.sprint_id,
        project_id: this.projectId || sprint.project_id,
        time_spent: newValue,
      };
      this.onSave(payload)
        .then((data) => {
          event.target.innerText = data.time_spent || '';
        })
        .catch(() => {
          event.target.innerText = sprint.time_spent[date] || '';
        });
    },

    onCellHover(date, item) {
      if (!this.highlightHoveredRowAndColumn) {
        return;
      }
      this.hoveredCellDate = date;
      this.hoveredSprintId = item ? item.sprint_id : null;
    },
  },
};
</script>

<style scoped>
.time-tracking-table {
  border-collapse: collapse;
}

/*.time-tracking-row .day-cell {*/
/*  width: 10%;*/
/*}*/

.time-tracking-table th {
  font-size: 12px;
}

.theme--dark .time-tracking-table th {
  color: rgba(255, 255, 255, 0.7);
}

.theme--light .time-tracking-table th {
  color: rgba(0, 0, 0, 0.6);
}

.header-today {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.theme--dark .time-tracking-row td {
  border-top: 1px solid hsla(0, 0%, 100%, 0.12);
}

.theme--dark .time-tracking-row .day-cell {
  border-left: 1px solid hsla(0, 0%, 100%, 0.12);
}

.theme--light .time-tracking-row td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--light .time-tracking-row .day-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--light .time-tracking-row .day-cell:hover,
.theme--light .time-tracking-row .day-cell:focus {
  background-color: #e1f5fe !important;
}

.theme--dark .time-tracking-row .day-cell:hover,
.theme--dark .time-tracking-row .day-cell:focus {
  background-color: #121212 !important;
}

td,
th {
  padding: 0 4px !important;
}

.theme--light .hovered-time-tracking-row,
.theme--light .hovered-time-tracking-cell,
.theme--light .hovered-time-tracking-row td {
  background-color: #e1f5fe !important;
}
.theme--dark .hovered-time-tracking-row,
.theme--dark .hovered-time-tracking-cell,
.theme--dark .hovered-time-tracking-row td {
  background-color: #121212 !important;
}
</style>
