<template>
  <BaseFilterDialogAndChips
    :submitted-values="selectedValues"
    :hide-chips="hideChips"
    :show-reset-button="showResetButton"
    @open="copyFilterParams"
    @clear:filter="clearFilter"
    @reset="resetFilters"
    @submit="submitFilters"
  >
    <v-row dense>
      <v-col v-if="!hideProjectFilter" cols="12" sm="6">
        <ProjectAutocomplete
          v-model="params.project_id"
          @update:initial-item="
            updateAutocompleteValue('project_id', $event);
            setLocalEmployees($event ? $event.assignees : []);
          "
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
          v-model="params.priority"
          :items="projectIssuePriorities"
          :label="$t('projects.labels.priority')"
          clearable
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
          v-if="employeeOptions.length"
          v-model="params.assigned_to"
          :items="employeeOptions"
          :label="$t('projects.general.assigned_employee')"
          item-text="personal_data.full_name"
          item-value="user_id"
          clearable
        />

        <UserAutocomplete
          v-else
          v-model="params.assigned_to"
          :label="$t('projects.general.assigned_employee')"
          @update:initial-item="updateAutocompleteValue('assigned_to', $event)"
        />
      </v-col>

      <v-col cols="12" class="v-label">
        {{ $t('users.filter_by_status') }}
      </v-col>

      <v-col v-for="status in projectIssueStatuses" :key="status.value" cols="12" sm="6">
        <v-checkbox
          v-model="params.status"
          :label="status.text"
          :value="status.value"
          class="mt-0"
          hide-details
        />
      </v-col>
    </v-row>
  </BaseFilterDialogAndChips>
</template>

<script>
import filterMixin from '@/mixins/filter-mixin';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete';
import { mapGetters } from 'vuex';
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import UserAutocomplete from '@/components/autocompletes/UserAutocomplete';
import { getDefaultProjectIssueFilterParams } from '@/store/modules/project-issues-module';
import clone from 'just-clone';

export default {
  name: 'ProjectIssueFilter',

  components: {
    UserAutocomplete,
    BaseFilterDialogAndChips,
    ProjectAutocomplete,
  },

  mixins: [filterMixin],

  props: {
    hideProjectFilter: {
      type: Boolean,
      default: false,
    },
    hideChips: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      localEmployees: [],
      defaultParams: getDefaultProjectIssueFilterParams(),
    };
  },

  computed: {
    ...mapGetters('projectIssues', ['projectIssuePriorities', 'projectIssueStatuses']),

    showResetButton() {
      const filterParams = clone(this.filterParams);
      if (this.hideProjectFilter) {
        delete filterParams.project_id;
      }
      return JSON.stringify(filterParams) !== JSON.stringify(this.defaultParams);
    },

    employeeOptions() {
      if (this.employees.length) {
        return this.employees;
      }

      return this.localEmployees;
    },

    selectedValues() {
      const values = [];
      const { project_id, priority, assigned_to, status } = this.filterParams;

      if (project_id && this.autocompleteValues.project_id?.id === project_id) {
        values.push({
          key: 'project_id',
          value: this.autocompleteValues.project_id.title,
          label: this.$t('general.project'),
        });
      }

      if (priority) {
        values.push({
          key: 'priority',
          value: this.$t(`projects.issue_priorities.${priority}`),
          label: this.$t('projects.labels.priority'),
        });
      }

      if (assigned_to && this.autocompleteValues.assigned_to?.id === assigned_to) {
        values.push({
          key: 'assigned_to',
          value: this.autocompleteValues.assigned_to.personal_data.full_name,
          label: this.$t('projects.general.assigned_employee'),
        });
      } else if (assigned_to && this.employeeOptions.length) {
        const employee = this.employeeOptions.find((e) => e.user_id === assigned_to);
        values.push({
          key: 'assigned_to',
          value: employee.personal_data.full_name,
          label: this.$t('projects.general.assigned_employee'),
        });
      }

      for (let i = 0; i < status?.length; i++) {
        values.push({
          key: 'status',
          value: status[i],
          translatedValue: this.$t(`projects.issue_status.${status[i]}`),
        });
      }

      return values;
    },
  },

  methods: {
    setLocalEmployees(employees) {
      this.localEmployees = employees;
    },
  },
};
</script>
