<template>
  <div>
    <BaseActionMenu :actions="getProjectActions()" always-visible />
    <ProjectSprintDisplayedStatusesMenu :key="$route.params.projectId" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ProjectSprintDisplayedStatusesMenu from '@/components/ProjectSprintDisplayedStatusesMenu';
import BaseActionMenu from '@/components/base/BaseActionMenu';

export default {
  name: 'ProjectPageActions',

  components: { BaseActionMenu, ProjectSprintDisplayedStatusesMenu },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectUserStories', ['enableProjectUserStoryCheckboxes']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject', 'isMemberOfProject']),
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES']),

    getProjectActions() {
      const actions = [];

      if (this.$can(['projects.create.*'])) {
        actions.push({
          label: this.$t('projects.controls.edit_project'),
          icon: 'edit',
          callback: () => this.$router.push({ name: 'project_editProject' }),
        });
      }

      if (
        this.currentUserPermissionInSelectedProject !== 'client' &&
        this.selectedProject.configurations.length
      ) {
        actions.push({
          label: this.$t('general.configurations'),
          icon: 'build',
          callback: () => this.$router.push({ name: 'projectConfigurations' }),
        });
      }

      if (this.currentUserPermissionInSelectedProject === 'admin') {
        if (this.enableProjectUserStoryCheckboxes) {
          actions.push({
            label: this.$t('projects.disable_user_story_selection_for_moving'),
            icon: 'check_box',
            callback: () => this.SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES(false),
          });
        } else {
          actions.push({
            label: this.$t('projects.enable_user_story_selection_for_moving'),
            icon: 'check_box_outline_blank',
            callback: () => this.SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES(true),
          });
        }
      }

      if (this.currentUserPermissionInSelectedProject !== 'client') {
        actions.push({
          label: this.$t('general.team'),
          icon: 'group',
          callback: () => this.$router.push({ name: 'projectTeam' }),
        });
      }

      if (this.currentUserPermissionInSelectedProject !== 'client') {
        actions.push({
          label: this.$t('projects.tabs.stats'),
          icon: 'bar_chart',
          callback: () => this.$router.push({ name: 'projectPointStatistics' }),
        });
      }

      actions.push({
        label: this.$t('projects.general.shown_sprints_statuses'),
        class: 'js-toggle-sprint-statuses-menu',
        icon: 'visibility',
      });

      if (this.isMemberOfProject) {
        actions.push({
          label: this.$t('general.my_preferences'),
          icon: 'settings',
          callback: () => this.$router.push({ name: 'projectSettings' }),
        });
      }

      return actions;
    },
  },
};
</script>

<style scoped></style>
