<template>
  <v-expansion-panel class="mb-3 mt-0">
    <v-expansion-panel-header class="project-page-panel-header" expand-icon="">
      <div class="text-subtitle-2">
        {{ $t('general.time_tracking').toUpperCase() }}
      </div>
      <div class="d-flex align-center justify-end">
        <v-btn
          :disabled="loading['get:api/time-tracking']"
          class="mr-1"
          icon
          @click.stop="viewWeek(-1)"
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-btn :disabled="loading['get:api/time-tracking']" icon @click.stop="viewWeek(1)">
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="sprint-panel mt-0">
      <v-progress-circular
        v-if="showLoadingSpinner"
        color="primary"
        class="mx-4 mb-4"
        indeterminate
      />

      <template v-else>
        <TimeTrackingTable
          v-if="timeTrackingEntriesByUser[currentUser.id]"
          :loading="loading['get:api/time-tracking']"
          :projects="timeTrackingEntriesByUser[currentUser.id]"
          :filter-params="timeTrackingFilterParams"
          :user-full-name="$isAdmin() ? currentUser.personal_data.full_name : ''"
          :project-id="+$route.params.projectId"
          :user-id="currentUser.id"
          :on-save="storeTimeTrackingEntry"
          can-change
        />

        <div v-for="assignee in otherAssignees" :key="assignee.id">
          <TimeTrackingTable
            v-if="timeTrackingEntriesByUser[assignee.user_id]"
            :loading="loading['get:api/time-tracking']"
            :projects="timeTrackingEntriesByUser[assignee.user_id]"
            :filter-params="timeTrackingFilterParams"
            :user-full-name="assignee.personal_data.full_name"
            :project-id="+$route.params.projectId"
            :user-id="assignee.user_id"
            :on-save="storeTimeTrackingEntry"
            opaque
            hide-headers
            can-change
          />
        </div>
        <v-divider v-if="$isAdmin()" />

        <div v-if="$isAdmin()" class="text-body-2 pa-2 text-right">
          <span class="font-weight-bold">{{ totalHours }}</span> {{ $t('general.hours_this_week') }}
        </div>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TimeTrackingTable from '@/components/tables/TimeTrackingTable';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';
import { addWeeks, endOfWeek, format, startOfWeek } from 'date-fns';

export default {
  name: 'ProjectPageTimeTracking',

  components: { TimeTrackingTable },

  computed: {
    ...mapState('timeTracking', ['timeTrackingEntriesByUser', 'timeTrackingFilterParams']),
    ...mapState('projects', ['selectedProject']),
    ...mapGetters(['loading']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    otherAssignees() {
      if (
        this.$isAdmin() ||
        this.currentUserPermissionInSelectedProject === 'admin' ||
        (this.$isEmployee && this.$can(['projects.*']))
      ) {
        return (
          this.selectedProject.assignees?.filter(
            (a) =>
              a.permission !== 'client' &&
              a.permission !== 'supplier' &&
              a.user_id !== this.currentUser.id,
          ) || []
        );
      }
      return [];
    },

    totalHours() {
      if (!this.$isAdmin()) {
        return 0;
      }
      let total = 0;
      Object.values(this.timeTrackingEntriesByUser).forEach((projects) => {
        projects.forEach((project) => {
          project.sprints.forEach((sprint) => {
            Object.values(sprint?.time_spent).forEach((hours) => {
              total += +hours;
            });
          });
        });
      });
      return total;
    },

    showLoadingSpinner() {
      return (
        !this.timeTrackingEntriesByUser[this.currentUser.id] &&
        this.loading['get:api/time-tracking']
      );
    },
  },

  watch: {
    '$route.params.projectId': {
      handler(newValue, oldValue) {
        if (+newValue === +oldValue) {
          return;
        }
        this.RESET_TIME_TRACKING_ENTRIES_BY_USER();
        this.fetchTimeTrackingData({
          date_from: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
          date_to: format(endOfWeek(new Date()), 'yyyy-MM-dd'),
        });
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.SET_TIME_TRACKING_FILTER_PARAMS(getDefaultTimeTrackingFilterParams());
  },

  methods: {
    ...mapActions('timeTracking', ['fetchTimeTrackingEntries', 'storeTimeTrackingEntry']),
    ...mapMutations('timeTracking', [
      'RESET_TIME_TRACKING_ENTRIES_BY_USER',
      'SET_TIME_TRACKING_FILTER_PARAMS',
    ]),

    fetchTimeTrackingData(params) {
      this.fetchTimeTrackingEntries({
        ...params,
        project_id: +this.$route.params.projectId,
        user_id: this.currentUser.id,
      });

      for (let i = 0; i < this.otherAssignees.length; i++) {
        this.fetchTimeTrackingEntries({
          ...params,
          project_id: +this.$route.params.projectId,
          user_id: this.otherAssignees[i].user_id,
        });
      }
    },

    onEntryChange(entry, userId) {
      const payload = {
        ...entry,
        user_id: userId,
        project_id: this.$route.params.projectId,
      };
      this.storeTimeTrackingEntry(payload);
    },

    viewWeek(offset) {
      const dateFrom = addWeeks(new Date(this.timeTrackingFilterParams.date_from), offset);
      const dateTo = addWeeks(new Date(this.timeTrackingFilterParams.date_to), offset);

      this.fetchTimeTrackingData({
        ...this.timeTrackingFilterParams,
        date_from: format(dateFrom, 'yyyy-MM-dd'),
        date_to: format(dateTo, 'yyyy-MM-dd'),
      });
    },
  },
};
</script>

<style scoped></style>
